<template>
  <div class="content">
    <!-- <PageHeader title="Omni Business Dashboard" /> -->
    <PageHeader title="Chat Dashboard" />
    <div class="page-content container-fluid">
      <!-- main -->
      <div class="row main">
        <div class="col-md-6 col-xl-3">
          <div class="card p-3">
            <div>
              <h3>Atendimentos</h3>
              <strong class="py-3">{{open}}</strong>
            </div>
            <i class="fas fa-users bg"></i>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3">
            <div>
              <h3>Em espera</h3>
              <strong class="py-3">{{queue}}</strong>
            </div>
            <i class="fas fa-bell bg"></i>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3">
            <div>
              <h3>Maior tempo de espera</h3>
              <strong class="py-3">{{maxWait | roundTime}}</strong>
            </div>
            <i class="fas fa-hourglass-end bg"></i>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card p-3">
            <h3>Agentes</h3>
            <!-- <progress value="50" max="100" class="my-2"></progress> -->
            <div class="py-2">
              <div class="row actives">
                <div class="col-3">
                  <!-- <h4>Online</h4> -->
                  <span class="status bg-success"></span> {{agentsOn}}
                </div>
                <div class="col-3">
                  <!-- <h4>Pausa</h4> -->
                  <span class="status bg-info"></span> {{agentsBreak}}
                </div>
                <div class="col-3">
                  <!-- <h4>Indisponíveis</h4> -->
                  <span class="status bg-warning"></span> {{agentsUnavailable}}
                </div>
                <div class="col-3">
                  <!-- <h4>Offline</h4> -->
                  <span class="status bg-danger"></span> {{agentsOff}}
                </div>
              </div>
            </div>
            <div class="progress">
              <b-button
                class="on"
                v-b-tooltip.hover
                variant="success"
                :title="`${agentsOn} ${agentsOn === 0 || agentsOn > 1 ? 'agentes disponíveis' : 'agente disponível'}`"
                :style="`width:${(agentsOn / agentsTotal) * 100}%`"></b-button>
              <b-button
                class="on"
                v-b-tooltip.hover
                variant="warning"
                :title="`${agentsUnavailable} ${agentsUnavailable === 0 || agentsUnavailable > 1 ? 'agentes indisponíveis' : 'agente indisponível'}`"
                :style="`width:${(agentsUnavailable / agentsTotal) * 100}%`"></b-button>
              <b-button
                class="on"
                v-b-tooltip.hover
                variant="info"
                :title="`${agentsBreak} ${agentsBreak === 0 || agentsBreak > 1 ? 'agentes' : 'agente'} em pausa`"
                :style="`width:${(agentsBreak / agentsTotal) * 100}%`"></b-button>
              <!-- <span :style="`width:${agentsOn - agentsUnavailable - agentsBreak - agentsOff}%`"></span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive card">
        <!-- qeues -->
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Filas</th>
              <th scope="col">Ativos</th>
              <th scope="col">Em espera</th>
              <th scope="col">Tempo médio</th>
              <th scope="col">Tempo máximo</th>
              <th scope="col">Agentes disponíveis</th>
              <th scope="col">Agentes indisponíveis</th>
              <th scope="col">Agentes em pausa</th>
              <th scope="col">Agentes offline</th>
              <th scope="col">Canais</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <i class="fas fa-layer-group"></i> Todos
              </th>
              <td>{{open}}</td>
              <td>{{queue}}</td>
              <td>{{averageWait | roundTime}}</td>
              <td>{{maxWait | roundTime}}</td>
              <td>{{agentsOn}}</td>
              <td>{{agentsUnavailable}}</td>
              <td>{{agentsBreak}}</td>
              <td>{{agentsOff}}</td>
              <td class="channels"></td>
            </tr>
            <template v-for="(entry, i) in $live.administer">
              <tr :key="i" class="sub">
                <th scope="row">
                  <i class="fas fa-globe" v-if="entry.generic"></i>
                  <i class="fas fa-users" v-else></i>
                  {{entry.generic ? 'Geral' : entry.department}}
                </th>
                <td>{{entry.open_room}}</td>
                <td>{{entry.queue_size}}</td>
                <td>{{entry.average_wait | roundTime}}</td>
                <td>{{entry.maximum_wait | roundTime}}</td>
                <td>{{entry.agent_available}}</td>
                <td>{{entry.agent_unavailable}}</td>
                <td>{{entry.agent_break}}</td>
                <td>{{entry.agent_off}}</td>
                <td class="channels">
                  <template v-for="(sEntry, j) in entry.channels">
                  <b-button
                    v-b-tooltip.hover
                    :title="`${sEntry.size} atendimento(s)`"
                    :key="j">
                      <i class="fas fa-globe" v-if="sEntry.channel === 'widget'"></i>
                      <i class="fab fa-facebook-messenger" v-else-if="sEntry.channel === 'messenger'"></i>
                      <i class="fab fa-whatsapp" v-else-if="sEntry.channel === 'whatsapp' || sEntry.channel === 'whatsapp-java'"></i>
                      <i class="fab fa-google" v-else-if="sEntry.channel === 'rcs'"></i>
                      <i class="fab fa-telegram" v-else-if="sEntry.channel === 'telegram'"></i>
                      <i class="fab fa-instagram" v-else-if="sEntry.channel === 'instagram'"></i>
                      <i class="fas fa-envelope" v-else-if="sEntry.channel === 'email'"></i>
                      <GoogleBusinessIcon v-else-if="sEntry.channel === 'google-business'" />
                      <i class="fas fa-question" v-else></i>
                      <span class="sr-only">{{sEntry.channel}}</span>
                    </b-button>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import GoogleBusinessIcon from '@/components/GoogleBusinessIcon.vue';

export default {
  name: 'LiveDashboard',
  components: {
    PageHeader,
    GoogleBusinessIcon,
  },
  computed: {
    open() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.open_room;
      });

      return sum;
    },
    queue() {
      let sum = 0;

      this.$live.administer.forEach((entry) => {
        sum += entry.queue_size;
      });

      return sum;
    },
    averageWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.average_wait > check) check = entry.average_wait;
      });

      return check;
    },
    maxWait() {
      let check = 0;

      this.$live.administer.forEach((entry) => {
        if (entry.maximum_wait > check) check = entry.maximum_wait;
      });

      return check;
    },
    agentsOn() {
      return this.$live.administer?.[0]?.['agent_available'];
    },
    agentsUnavailable() {
      return this.$live.administer?.[0]?.['agent_unavailable'];
    },
    agentsBreak() {
      return this.$live.administer?.[0]?.['agent_break'];
    },
    agentsOff() {
      return this.$live.administer?.[0]?.['agent_off'];
    },
    agentsTotal() {
      return this.agentsOn + this.agentsUnavailable + this.agentsBreak + this.agentsOff;
    },
    // channels() {
    //   const channels = [];

    //   this.$live.administer.forEach((entry) => {
    //     if (entry.channels && entry.channels.length) {
    //       entry.channels.forEach((subEntry) => {
    //         const name = subEntry.channel;
    //         let found = channels.find((el) => el.name === name);

    //         if (!found) {
    //           found = {
    //             name,
    //             openRoom: 0,
    //             queueSize: 0,
    //             averageWait: 0,
    //             maximumWait: 0,
    //             agentAvailable: 0,
    //             agentUnavailable: 0,
    //             agentBreak: 0,
    //             agentOff: 0,
    //           };
    //           channels.push(found);
    //         }

    //         found.openRoom += entry.open_room;
    //         found.queueSize += entry.queue_size;
    //         found.averageWait += entry.average_wait;
    //         found.maximumWait += entry.maximum_wait;
    //         found.agentAvailable += entry.agent_available;
    //         found.agentUnavailable += entry.agent_unavailable;
    //         found.agentBreak += entry.agent_break;
    //         found.agentOff += entry.agent_off;
    //       });
    //     }
    //   });

    //   return channels;
    // },
  },
  filters: {
    roundTime: (value) => {
      const days = Math.floor(value / 1440);

      // console.log('roundTime.days', days);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      // console.log('roundTime.hours', hours);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      // console.log('roundTime.mins', value);

      return `${Math.floor(value)} min${value < 2 ? '' : 's'}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main .card {
  position: relative;
  height: 150px;
  overflow: hidden;

  h3 {
    font-size: 90%;
    font-weight: bold;
  }

  & > div {
    position: relative;
    z-index: 5;
  }

  .bg {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 4;
    opacity: .15;
    font-size: 64pt;
  }

  & > div > strong {
    display: block;
    font-size: 200%;
  }

  .actives {
    * {
      font-size: 92%;
    }

    .status {
      vertical-align: middle;
      display: inline-block;
      border-radius: 8px;
      width: 12px;
      height: 12px;
    }
  }

  // progress {
  //   width: 100%;
  //   height: 8px;
  //   background: #ccc;
  //   border-radius: 6px;

  //   &::-webkit-progress-bar {
  //     background: transparent;
  //     border-radius: 6px;
  //   }

  //   &::-webkit-progress-value {
  //     border-radius: 6px;
  //     background: #0E1421;
  //   }

  //   &::-moz-progress-bar {
  //     background: #0E1421;
  //   }
  // }

  .progress {
    button::v-deep {
      border-radius: 0;
      border: none;
      padding: 0;
      margin: 0;
      height: auto !important;
    }
  }
}

table {
  min-width: 600px;

  th {
    white-space: nowrap;
  }

  tr > :first-child i {
    text-align: center;
    width: 20px;
  }

  tr.sub > :first-child i {
    margin-left: .4rem;
  }

  .channels {
    button::v-deep {
      background: transparent !important;
      height: 24px !important;
      width: 24px !important;
      min-width: 1px;
      padding: 0;
      margin-right: 2px;

      &::last-child {
        margin-right: 0;
      }
    }
  }

  th[scope=col]:not(:first-child), td {
    text-align: center;
  }

  th[scope=row] {
    text-transform: capitalize;
  }
}
</style>
